import base from "@reflexjs/preset-base"

export default {
  preset: base,

  colors: {
    primary: "#0b5394ff",
    primaryHover: "black",
  },
  fonts: {
    body: 'PT Sans',
    heading: 'Exo 2'
  }
}
