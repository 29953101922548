import * as React from "react"
import { MDXRenderer, Link } from "@reflexjs/gatsby-theme-core"
import { Article, Container, H1, Div, P, Flexbox } from "@reflexjs/components"
import { Image } from "@reflexjs/gatsby-plugin-image"
import { Icon } from "@reflexjs/gatsby-theme-core"

export const Post = ({
  title,
  excerpt,
  body,
  image,
  caption,
  tags, 
  link
}) => (
  <Article py="8|12|14">
    <Container maxW="null|null|null|900px">
      <Div textAlign="center">
        {tags && (
          <Link to={tags[0].slug} textDecoration="none" color="text">
            {tags[0].name}
          </Link>
        )}
        {title && (
          <H1 my="0" fontWeight="extrabold">
            {title}
          </H1>
        )}
        {image && (
          <Image
            src={image}
            title={title}
            alt={title}
            maxW="70%"
            mx="auto"
          />  
        )}
        {excerpt && (
          <P fontSize="2xl" maxW="700px" mx="auto">
            {excerpt}
          </P>
        )}
      </Div>
    </Container>


    <Container maxW="null|null|null|900px">
      <MDXRenderer>{body}</MDXRenderer>

      {caption && ( 
        <Link href={caption}  target="_blank">
          <Icon name="arrow-right" />&nbsp;lien vers le site de {title}
        </Link>
      )}

      {tags && (
        <Flexbox alignItems="center" my="6" mx="auto">
          {tags.map(({ name, slug }, index) => (
            <Link
              key={name}
              to={slug}
              display="inline-block"
              bg="muted"    
              px="2"
              py="1"
              rounded="md"
              color="text"
              textDecoration="none"
              fontSize="sm"
              ml={index !== 0 && 2}
              hoverBg="secondary"
              hoverColor="white"
            >
              {name}
            </Link>
          ))}
        </Flexbox>
      )}
    </Container>
  </Article>
)